import {
  Typography,
  Grid,
  Stepper,
  Box,
  StepLabel,
  Step,
  StepContent,
  Container,
  FormControl,
  Button,
  Card,
  FormHelperText,
} from "@mui/material";
import React, { useRef, useState } from "react";
import Logo from "./../../images/home/logo.png";
import SideLogo from "./logos.png";
import GoogleIcon from "../../images/home/google.svg";

import Stars from "./../../images/login/stars.svg";
import Mail from "./../../images/login/mail.svg";
import MultiUser from "./../../images/login/multiUser.svg";
import Admin from "./../../images/login/admin.svg";
import Tick from "./../../images/login/Tick.svg";
import { MUI } from "../../components/CustomComponents";
import { Formik } from "formik";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import { loginApi } from "../../_services/login";
import styled from "styled-components";
import Alert from "../../components/alert";
import { setToken, loginSuccess } from "../../store/bugs";
import { connect } from "react-redux";
import Cookies from "universal-cookie";
import PaymentCourosel from "../Profile/PaymentCourosel";
import { payments } from "../../_services/payments";
import { useGoogleLogin } from "@react-oauth/google";

const SignUp = (props) => {
  const { CustomTypography, CustomInput, LoginButton } = MUI;
  const [activeStep, setActiveStep] = React.useState(0);
  const [loading, setLoading] = useState(false);
  const [severity, setSeverity] = useState(null);
  const [gmailData, setGmailData] = useState(null);

  let initialSteps = [
    {
      label: "Your Details",
      description: `Please provide your name and email`,
    },
    {
      label: "Verify you email",
      description: "Check your email and enter OTP",
    },
    // {
    //   label: "Company Info",
    //   description: `Enter details of your company`,
    // },
    {
      label: "Payment Details",
      description: `Make payment for subscription`,
    },
    {
      label: "Welcome to Cloud Zilla",
      description: `Your file world, Your rules`,
    },
  ];
  const [steps, setSteps] = useState(initialSteps);
  

  const [message, setMessage] = useState();
  const cookies = new Cookies();

  const [open, setOpen] = useState(false);
  let initData={
    userName: "",
    email: "",
    contact: "",
    password: "",
    confirmPassword: "",
    step: 0,
    gmailData:false,
    workspace: "",
    companyName: "",
    companyAddress: "",
    billingAddress: "",
    otp1: "",
    otp2: "",
    otp3: "",
    otp4: "",
  }
  const [initialData,setInitialData] = useState(initData)

  const navigate = useNavigate();
  const passwordRegex =
    /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;

  

  const StepperImage = styled.img`
    object-fit: "contain";
    aspect-ratio: 3/2;
    width: 20px;
  `;
  function ColorlibStepIcon(props) {
    const icons = {
      1: <StepperImage src={activeStep > 0 ? Tick : Admin} alt="logo" />,
      2: <StepperImage src={activeStep > 1 ? Tick : Mail} alt="logo" />,
      3: <StepperImage src={activeStep > 2 ? Tick : Stars} alt="logo" />,
      4: <StepperImage src={activeStep > 3 ? Tick : MultiUser} alt="logo" />,
      5: <StepperImage src={activeStep > 4 ? Tick : Stars} alt="logo" />,
    };

    return (
      <div
        style={{
          padding: "4px",
          border: "1px solid #eaecf0",
          borderRadius: "4px",
          width: "100%",
        }}
      >
        {icons[String(props.icon)]}
      </div>
    );
  }

  const renderInputField = (
    label,
    name,
    value,
    placeholder,
    handleChange,
    touched,
    error,
    type
  ) => {
    return (
      <FormControl variant="standard" sx={{ marginTop: "4px" }} fullWidth>
        <CustomTypography>{label}</CustomTypography>
        <CustomInput
          error={Boolean(touched && error)}
          name={name}
          disabled={gmailData &&  name ==="email" }
          value={value}
          autocomplete="off"
          type={type ? type : "text"}
          onChange={handleChange}
          placeholder={placeholder}
          size="small"
        />
        <FormHelperText error sx={{ fontFamily: "Inter" }}>
          {touched && error}
        </FormHelperText>
      </FormControl>
    );
  };

  const renderTitle = (title, description) => {
    return (
      <Box
        alignItems={"center"}
        mb={3}
        display={"flex"}
        flexDirection={"column"}
      >
        <img
          style={{ marginBottom: "1rem" }}
          src={Logo}
          width={200}
          alt="logo"
        />
        <CustomTypography
          variant="h6"
          sx={{ color: "#101828", fontSize: "30px" }}
        >
          {title}
        </CustomTypography>
        <CustomTypography sx={{ color: "#475467" }}>
          {description}
        </CustomTypography>
      </Box>
    );
  };

  

  const googleSignup = (values) => {
    const data = {
      username: values.userName,
      email: values.email,
      workspace_name: values.workspace,
      token:values.gmailData?.token,
      // workspace_name: values.workspace ? values.workspace : "testing",
    };
    setLoading(true);
    try {
      loginApi.googleSignup(data).then((res) => {
        setLoading(false);
        if (res.status === 201) {
          setActiveStep(activeStep + 1);
          setWorkspaceId(res.data.data.workspace_id);
        } else if (res.status === 400 && res.data.message === "Workspace already exists") {
          setOpen(true);
          setSeverity("error");
          setInitialData({...values,workspace:''})
          setMessage(res?.data?.message);
        } else {
          setOpen(true);
          setGmailData(false)
          setInitialData(initData)
          setSeverity("error");
          setMessage(res?.data?.message);
        }
      });
    } catch {
      setLoading(false);
      setOpen(true);
    }
  };

  const registerNewUser = (values) => {
    const data = {
      username: values.userName,
      email: values.email,
      phone: values.contact,
      password: values.password,
      workspace_name: values.workspace,
      // workspace_name: values.workspace ? values.workspace : "testing",
    };
    setLoading(true);
    try {
      loginApi.registerNewUser(data).then((res) => {
        setLoading(false);
        if (res.status === 200) {
          setActiveStep(activeStep + 1);
          setSeverity("error");
          cookies.set("token", res.data.token);
        } else {
          setOpen(true);
          setGmailData(false)
          setInitialData(initData)
          setSeverity("error");
          setMessage(res?.data?.message);
        }
      });
    } catch {
      setLoading(false);
      setOpen(true);
    }
  };

  // const registerWorkspace =async (values) => {
  //   const data = {
  //     workspace_name: values.workspace,
  //     company_name: values.companyName,
  //     office_address: values.companyAddress,
  //     billing_address: values.billingAddress,
  //     // workspace_name: values.workspace ? values.workspace : "testing",
  //   };
  //   setLoading(true);
  //   try {
  //    await loginApi.registerWorkspace(data).then((res) => {
  //      setLoading(false);
  //       if (res.status ===200) {
  //         setActiveStep(activeStep + 2);
  //         setSeverity("error");
  //         localStorage.setItem('token',res.data.token)
  //         setMessage("Registered Successfully");
  //       } else {
  //         setOpen(true);
  //         setSeverity("error");
  //         setMessage(res?.data?.message);
  //       }
  //     });
  //   } catch {
  //     setLoading(false);
  //     setSeverity("error");
  //     setMessage("Registration Failed");
  //     setOpen(true);
  //   }
  // };

  const handleClose = () => {
    setOpen(false);
  };

  const handleResendOtp = (email) => {
    const data = {
      email: email,
    };
    loginApi.resendOtpforEmail(data).then((res) => {
      if (res.status === 200) {
        setOpen(true);
        setSeverity("success");
        setMessage("OTP sent successfully");
      } else {
        setOpen(true);
        setSeverity("error");
        setMessage("OTP sending failed");
      }
    });
  };
  let [workspaceId, setWorkspaceId] = useState(0);
  const handleVerifyEmail = (email) => {
    const data = {
      email: email,
      OTP: otp.join(""),
    };
    setLoading(true);
    loginApi.verifyEmail(data).then((res) => {
      setLoading(false);
      if (res.status === 200) {
        setWorkspaceId(res.data.data.workspace_id);
        setActiveStep(activeStep + 1);
      } else {
        setOpen(true);
        setSeverity("error");
        setMessage("Invalid OTP");
      }
    });
  };


  const loadRazorpayScript = () => {
    return new Promise((resolve) => {
      const script = document.createElement("script");
      script.src = "https://checkout.razorpay.com/v1/checkout.js";
      script.onload = () => resolve(true);
      script.onerror = () => resolve(false);
      document.body.appendChild(script);
    });
  };

  const handlePayment = async (values) => {
    try {
      const isScriptLoaded = await loadRazorpayScript();
      if (!isScriptLoaded) {
        alert("Failed to load Razorpay SDK. Please try again later.");
        return;
      }
      // Create an order on the backend
      let data = {
        plan_id: values.id,
        workspace_id: workspaceId,
      };
      await loginApi.createOrder(data).then((res) => {
        if (res.data.status) {
          const options = {
            key: "rzp_test_j5pzqGxqRuC8FP", // Replace with your Razorpay Key ID
            amount: res.data.order.amount, // Amount in paise
            currency: res.data.order.currency,
            name: "Cloudzilla",
            description: "Razorpay Integration",
            order_id: res.data.order.id,
            handler: function (response) {
              let data = {
                order_id: response.razorpay_order_id,
                payment_id: response.razorpay_payment_id,
                signature: response.razorpay_signature,
              };
              payments
                .verifyPlan(data)
                .then((response) => {
                  if (response.status === 200) {
                    setOpen(true);
                    setSeverity("success");
                    setMessage("Payment successful");
                    setActiveStep(activeStep + 1);
                  } else {
                    setOpen(true);
                    setSeverity("error");
                    setMessage("Payment verification failed");
                  }
                })
                .catch((error) => {
                  console.error("Error verifying payment:", error);
                });
            },
            prefill: {
              name: values.userName,
              email: values.email,
              contact: values.contact,
            },
            theme: {
              color: "#F37254",
            },
          };
          const razorpay = new window.Razorpay(options);
          razorpay.open();
        } else {
          setOpen(true);
          setSeverity("error");
          setMessage(res.data.message);
        }
      });

      // Initialize Razorpay
    } catch (error) {
      console.error("Error creating order:", error);
    }
  };

  const [otp, setOtp] = useState(["", "", "", ""]);

  const otpInputRefs = [useRef(null), useRef(null), useRef(null), useRef(null)];

  const handleOtpInputChange = (index, e) => {
    const value = e.target.value;
    if (isNaN(value)) return false;
    setOtp([...otp.map((d, id) => (id === index ? value : d))]);
    // Move to the next field if there is a value
    if (e.target.value.length === 1 && index !== 3) {
      otpInputRefs[index + 1].current.focus();
    } else if (e.target.value.length === 0 && index !== 0) {
      otpInputRefs[index - 1].current.focus();
    }
  };

  // const handleStep = (step) => () => {
  //   setActiveStep(step);
  // };

  const userValidation = Yup.object().shape({
    userName: Yup.string().required("Please enter username"),
    email: Yup.string()
      .required("Please enter email")
      .email("Please enter a valid email"),
    workspace: Yup.string().required("Please enter workspace"),
    password:gmailData ? Yup.string() :  Yup.string()
        .matches(
          passwordRegex,
          "Password must contain at least eight characters, one letter, one number, and one special character"
        )
        .required("Please enter password"),
    confirmPassword:gmailData ? Yup.string() :  Yup.string()
        .required("Please enter confirm password")
        .oneOf([Yup.ref("password"), null], "Passwords must match"),
  });

  // const companyValidation = Yup.object().shape({
  //   workspace: Yup.string().required("Please enter workspace"),
  //   companyAddress: Yup.string().required("Please enter company address"),

  //   billingAddress: Yup.string().required(" Please enter billing address"),
  //   companyName: Yup.string().required("Please enter company name"),
  // });

  const login = useGoogleLogin({
    onSuccess: async (tokenResponse) => {
      const accessToken = tokenResponse.access_token;
      // Step 2: Fetch user info from Google API
      const userInfoResponse = await fetch(
        'https://www.googleapis.com/oauth2/v3/userinfo',
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      const userInfo = await userInfoResponse.json();
      if(userInfo){
        setSteps(initialSteps.filter((item)=>item.label !=="Verify you email"))
      }
      let responseData ={
        token:accessToken,
        googleId:userInfo.sub
      }
      setGmailData(responseData)
      setInitialData({
        ...initialData,userName:userInfo.name,email:userInfo.email,gmailData:responseData
      })
      setOpen(true);
      setSeverity("success");
      setMessage("Authentication successful, please fill workspace and continue...");
    },
  });

  return (
    <Box sx={{ background: "#EEF4FC" }}>
      <Container
        sx={{
          minHeight: "100vh",
          // margin:'auto auto',
          alignItems: "center",
          display: "flex",
        }}
      >
        <Card
          sx={{
            width: "100%",
            margin: "1rem 0",
            boxShadow: "1px 1px 10px 0px lightGrey",
            borderRadius: "8px",
          }}
        >
          <Grid container>
            <Grid
              item
              xs={6}
              md={4}
              p={5}
              display={"flex"}
              flexDirection={"column"}
              alignItems={"start"}
              justifyContent={"space-between"}
              sx={{ minHeight: "80vh", backgroundColor: "#fafafa" }}
            >
              <Box>
                <img
                  style={{ marginLeft: "-1rem", marginBottom: "2rem" }}
                  src={SideLogo}
                  width={50}
                  alt="logo"
                />
                <Stepper
                  nonLinear
                  activeStep={activeStep}
                  orientation="vertical"
                >
                  {steps.map((step, index) => (
                    <Step key={step.label}>
                      <StepLabel
                        sx={{
                          "&.MuiStepLabel-root": {
                            padding: 0,
                          },
                        }}
                        // onClick={handleStep(index)}
                        StepIconComponent={ColorlibStepIcon}
                      >
                        <span
                          style={{
                            fontFamily: "Inter",
                            color: activeStep === index ? "#344054" : "#838a97",
                            fontWeight: 600,
                            fontSize: "14px",
                          }}
                        >
                          {step.label}
                        </span>
                        <br />
                        <span
                          style={{
                            color: activeStep === index ? "#475467" : "#8e96a2",
                            fontSize: 12,
                            fontWeight: 400,
                          }}
                        >
                          {step.description}
                        </span>
                      </StepLabel>
                      <StepContent></StepContent>
                    </Step>
                  ))}
                </Stepper>
              </Box>
              <Box  sx={{width:'100%'}}>
              <Box
                display={"flex"}
                justifyContent={"space-between"}
              >
                <Button
                  disabled={activeStep === 0 || activeStep === 2}
                  sx={{ color: "#000", padding: 0,paddingRight:'2rem',textAlign:'left', textTransform: "none" }}
                  onClick={() => activeStep && setActiveStep(activeStep - 1)}
                >
                  Back
                </Button>
                <Button
                  disableRipple
                  disableFocusRipple
                  sx={{ color: "#000", padding: 0, textTransform: "none" }}
                  onClick={() => navigate("/signin")}
                >
                  Already have workspace?
                </Button>
              </Box>
              <Button
                fullWidth
                sx={{
                  textAlign: "center",
                  border: "1px solid #dadce0",
                  borderRadius: "4px",
                  marginBottom: "2rem",
                  marginTop:'2rem',textTransform:'none'
                }}
                disabled={gmailData}
                onClick={login}
              >
                <img
                  src={GoogleIcon}
                  style={{ marginRight: "10px" }}
                  alt=""
                  width={20}
                />
                Sign up with Google
              </Button>
              </Box>
            </Grid>
            <Grid
              item
              xs={6}
              md={8}
              mt={5}
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                overflow: "hidden",
                transition: "max-height 0.3s ease-out",
                width: "100%",
              }}
            >
              <Formik
              enableReinitialize
                initialValues={initialData}
                validationSchema={
                  // activeStep === 0 ? userValidation : companyValidation
                  userValidation
                }
                onSubmit={(values) => {
                  activeStep === 0
                    ? (gmailData ? googleSignup(values) : registerNewUser(values))
                    : ((activeStep === 2 && !gmailData) || (activeStep === 1 && gmailData))
                    ? handlePayment(values)
                    : navigate("/signin");
                }}
              >
                {({
                  errors,
                  handleChange,
                  handleSubmit,
                  touched,
                  values,
                }) => (
                  <form noValidate onSubmit={handleSubmit}>
                    	{/* <pre>{JSON.stringify({ values, errors }, null, 4)}</pre> */}
                    {activeStep === 0 ? (
                      <Container maxWidth={"xs"}>
                        {renderTitle(
                          "Create  workspace",
                          "Provide your personal details"
                        )}
                        {renderInputField(
                          "Username*",
                          "userName",
                          values.userName,
                          "Enter you username",
                          handleChange,
                          touched.userName,
                          errors.userName
                        )}
                        {renderInputField(
                          "Email*",
                          "email",
                          values.email,
                          "Enter your email",
                          handleChange,
                          touched.email,
                          errors.email
                        )}
                        {/* {renderInputField(
                        "Contact no*",
                        "contact",
                        values.contact,
                        "Enter your Contact number",
                        handleChange,
                        touched.contact,
                        errors.contact,
                        "number"
                      )} */}
                        {renderInputField(
                          "Workspace*",
                          "workspace",
                          values.workspace,
                          "Enter your workspace",
                          handleChange,
                          touched.workspace,
                          errors.workspace
                        )}
                       {gmailData ? null :
                        <>
                        {renderInputField(
                          "Password*",
                          "password",
                          values.password,
                          "Enter your password",
                          handleChange,
                          touched.password,
                          errors.password,
                          "password"
                        )}
                        {renderInputField(
                          "Confirm password*",
                          "confirmPassword",
                          values.confirmPassword,
                          "Enter your confirm password",
                          handleChange,
                          touched.confirmPassword,
                          errors.confirmPassword,
                          "password"
                        )}
                        </>
                       }
                      </Container>
                    ) : (activeStep === 1 && !gmailData) ? (
                      <Container maxWidth={"xs"}>
                        {renderTitle(
                          "Verify your email",
                          `We sent code to ${values.email}`
                        )}
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            gap: 2,
                          }}
                        >
                          {otp.map((item, index) => (
                            <CustomInput
                              key={index}
                              sx={{
                                width: "3.5rem",
                                fontSize: "2rem",
                                fontWeight: 600,
                                "& .MuiInputBase-input": {
                                  textAlign: "center",
                                },
                              }}
                              placeholder="-"
                              color="success"
                              name={item}
                              value={item}
                              inputRef={otpInputRefs[index]}
                              type="text"
                              variant="outlined"
                              margin="dense"
                              onChange={(e) => {
                                handleOtpInputChange(index, e);
                              }}
                              inputProps={{
                                maxLength: 1,
                              }}
                            />
                          ))}
                        </Box>
                        <Typography
                          sx={{ textAlign: "center", marginTop: "1rem" }}
                        >
                          Didn't get code?{" "}
                          <Typography
                            variant="a"
                            sx={{
                              fontWeight: "bold",
                              fontSize: "14px",
                              cursor: "pointer",
                              textDecoration: "underline",
                            }}
                            onClick={() => handleResendOtp(values.email)}
                          >
                            Click to resend
                          </Typography>
                        </Typography>
                        <LoginButton
                          disabled={otp.length !== 4}
                          fullWidth
                          onClick={() => handleVerifyEmail(values.email)}
                        >
                          Verify
                        </LoginButton>
                      </Container>
                    ) : ((activeStep === 2 && !gmailData) ||activeStep === 1) ? (
                      <PaymentCourosel handleSubmit={handlePayment} />
                    ) : ((activeStep === 3 && !gmailData) ||activeStep === 2) ? (
                      <Container maxWidth={"xs"}>
                        {renderTitle(
                          "Welcome to Cloud Zilla",
                          "Your File World, Your Rules"
                        )}
                        <Box
                          style={{
                            minHeight: 300,
                            border: "1px solid lightGrey",
                          }}
                        ></Box>
                      </Container>
                    ) : (
                      <Container maxWidth={"xs"}>
                        {renderTitle(
                          "Company Details",
                          "Enter your company details"
                        )}
                        {renderInputField(
                          "Company Name*",
                          "companyName",
                          values.companyName,
                          "Enter your company name",
                          handleChange,
                          touched.companyName,
                          errors.companyName
                        )}

                        {renderInputField(
                          "Company Address*",
                          "companyAddress",
                          values.companyAddress,
                          "Enter your company address",
                          handleChange,
                          touched.companyAddress,
                          errors.companyAddress
                        )}
                        {renderInputField(
                          "Billing Address*",
                          "billingAddress",
                          values.billingAddress,
                          "Enter your billing address",
                          handleChange,
                          touched.billingAddress,
                          errors.billingAddress
                        )}
                      </Container>
                    )}
                    {((activeStep === 1 || activeStep === 2) && !gmailData) || (activeStep === 1  && gmailData)  ? null : (
                      <Container
                        maxWidth="xs"
                        sx={{ width: "100%", textAlign: "center" }}
                      >
                        <LoginButton
                          type={"submit"}
                          // onClick={() => {
                          //   if(activeStep<4){
                          //     setActiveStep(activeStep+1)
                          //     setFieldValue("step", values.step + 1)
                          //   }
                          // }}
                          disabled={loading}
                          fullWidth
                          sx={{ textAlign: "center", marginBottom: "2rem" }}
                        >
                          {((activeStep !== 3 && !gmailData)||(activeStep !== 2 && gmailData) ) ? "Continue" : "Login to workspace"}
                        </LoginButton>
                      </Container>
                    )}
                  </form>
                )}
              </Formik>
            </Grid>
          </Grid>
        </Card>
        <Alert
          open={open}
          message={message}
          severity={severity}
          handleClose={handleClose}
        />
      </Container>
    </Box>
  );
};

const dispatchToProps = (dispatch) => ({
  setToken: (token) => dispatch(setToken(token)),
  loginSuccess: (auth) => dispatch(loginSuccess(auth)),
});

export default connect(null, dispatchToProps)(SignUp);