import React from "react";
import { useRoutes } from "react-router-dom";
import { ThemeProvider } from "@mui/material/styles";
import { classicTheme, defaultTheme } from "./theme";
import routes from "./routes";
import { Provider } from "react-redux";
import store from "./store/configureStore";
import { PersistGate } from "redux-persist/integration/react";
import { persistStore } from "redux-persist";
import "./App.css";
import { connect } from "react-redux";
import { GoogleOAuthProvider } from "@react-oauth/google"

// import dotenv from 'dotenv';
// dotenv.config();
const App = (props) => {
  const routing = useRoutes(routes);
  const theme = props.theme === "default" ? defaultTheme : classicTheme;
  return <ThemeProvider theme={theme}>{routing}</ThemeProvider>;
};

const mapStateToProps = (state) => {
  return {
    force: state, // force state from reducer
    theme: state.authReducer.theme,
  };
};

const ConnectedApp = connect(mapStateToProps)(App); // Connect the component

let persistor = persistStore(store);
const RootApp = () => (
  <GoogleOAuthProvider clientId='437152250229-5vdthdla6qccsk3afs8h7enk7pamfvqv.apps.googleusercontent.com'>
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <ConnectedApp />
    </PersistGate>
  </Provider>
  </GoogleOAuthProvider>
);

export default RootApp;

