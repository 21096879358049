import axios from "axios";
import { helpers } from "../_helpers";
const settings = require('../config');

const ApiEndpoint = settings.ApiEndPoint;

const apiEndpoint = ApiEndpoint;

export const payments = {
  createOrder,
  renewPlan,
  verifyPlan
};


  async function createOrder(payload) {
    helpers.setHeader();
    const requestOptions = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    try {
      const data = await axios.post(
        `${apiEndpoint}/create-order `,
        payload,
        requestOptions
      );
      return data;
    } catch (error) {
      return error.response;
    }
  }

  async function renewPlan(payload) {
    helpers.setHeader();
    const requestOptions = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    
    try {
      const data = await axios.post(
        `${apiEndpoint}/renew-plan`,
        payload,
        requestOptions
      );
      return data;
    } catch (error) {
      return error.response;
    }
  }

  async function verifyPlan(payload) {
    helpers.setHeader();
    const requestOptions = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    
    try {
      const data = await axios.post(
        `${apiEndpoint}/verify-payment`,
        payload,
        requestOptions
      );
      return data;
    } catch (error) {
      return error.response;
    }
  }

  