import React from 'react'
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import { Box, Card, Container, Stack, Typography } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { MUI } from '../../components/CustomComponents';

const PaymentCourosel = (props) => {
  const { LoginButton } = MUI;

    let plans=[{
        name:'Basic Plan',
        price:'299',
        features:['Upto 5 Settings','Supports AWS, GCP and Azure','Cloud Storage','Multiple functionalities'],
        plan:'Month',color:'#ffed85',
        id:1
      },{
        name:'Standard Plan',
        price:'499',
        features:['Upto 5 Settings','Supports AWS, GCP and Azure','Cloud Storage','Multiple functionalities'],
        plan:'Month',
        color:'#3effc5',
        id:2
      },{
        name:'Premium Plan',
        price:'2999',
        features:['Upto 5 Settings','Supports AWS, GCP and Azure','Cloud Storage','Multiple functionalities'],
        plan:'Year',
        color:'#abffbb',
        id:3
      }]

  const renderPlan = (plan,values) => {
    return (
      <div>
      <Card
        sx={{
          boxShadow: `inset 0px 0px 5px 0px ${plan.color}`,
          borderRadius: "10px",
          padding: "2rem",
          textAlign: "left",
          minHeight: "60vh",
          display: "flex",
          flexDirection: "column",
          my: 5,
          justifyContent: "space-between",
        }}
      >
        <Stack>
          <Box
            display="flex"
            alignItems={"center"}
            sx={{ fontFamily: "system-ui" }}
            my={2}
          >
            <Typography
              sx={{
                height: "16px",
                width: "16px",
                borderRadius: "100%",
                backgroundColor: plan.color,
                mr: 1,
                filter: "blur(6px)",
              }}
            ></Typography>
            <Typography>{plan.name}</Typography>
          </Box>
          <Typography sx={{ fontSize: "2rem" }}>
          ₹{plan.price}{" "}
            <Typography component={"span"}>
              /{plan.plan}
            </Typography>
          </Typography>
          {plan.features.map((feature)=>{
            return(
              <Box mt={5}  display={'flex'}>
              <AddIcon sx={{color:plan.color}}/>
              <Typography ml={2}>{feature}</Typography>
            </Box>
            )
          })}
        </Stack>
        <LoginButton
          // disabled={otp.length !== 4}
          fullWidth
          onClick={() => props.handleSubmit({...values,id:plan.id})}
        >
          {props.renewPlan ? "Renew" : "Get"} Plan
        </LoginButton>
      </Card>
    </div>
    )
  }
  return (
    <Container maxWidth={"xs"}>
    <Carousel
      showArrows={false}
      //  showIndicators={false}
      showStatus={false}
    >
     {plans.map((plan)=>(
      renderPlan(plan,props.values))
      )}
    </Carousel>
  </Container>
  )
}

export default PaymentCourosel