import React, { useEffect, useState } from 'react'
import Toolbar from '../../components/Toolbar/Toolbar'
import Page from '../../components/page'
import { Avatar, Box, Card, Typography, Grid, IconButton,Container,FormControl,FormHelperText, Stack } from '@mui/material'
import styled from 'styled-components'
import { CancelOutlined, CloudCircle, Edit, Email } from '@mui/icons-material'
import SettingsIcon from '@mui/icons-material/Settings';
import { useSelector } from 'react-redux';
import { loginApi } from '../../_services/login'
import Alert from "../../components/alert";
import * as Yup from "yup";
import { MUI } from "../../components/CustomComponents";
import { Formik } from "formik";
import CreateDialog from '../../components/Dialog/CreateDialog'
import PaymentCourosel from './PaymentCourosel'
import { payments } from '../../_services/payments'
import { apiData } from '../../_services/home'
import moment from 'moment'

const FrameParent = styled.div`
position: absolute;
top: 80px;
left: 240px;
width: calc(100vw - 240px);
height: 767px;
overflow: hidden;
display:flex;
justify-content:center;
color: var(--text-colors-text-secondary);
`;

const Profile = () => {
    const state = useSelector(state => state.authReducer.token)
    const [severity, setSeverity] = useState(false)
    const [message, setMessage] = useState(false)
    const [open, setOpen] = useState(false)
    const [paymentDialog, setPaymentDialog] = useState(false)
    const [loading, setLoading] = useState(false)

    // const {  FrameParent } = MUI;
    const { CustomTypography, CustomInput, LoginButton } = MUI;

    const [edit, setEdit] = useState(false)
    const passwordRegex = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;

    const sendOtpForEmail = (() => {
        setEdit(true)
    })

      const loadRazorpayScript = () => {
        return new Promise((resolve) => {
          const script = document.createElement("script");
          script.src = "https://checkout.razorpay.com/v1/checkout.js";
          script.onload = () => resolve(true);
          script.onerror = () => resolve(false);
          document.body.appendChild(script);
        });
      };

  const handlePayment = async (values) => {
    try {
      const isScriptLoaded = await loadRazorpayScript();
      if (!isScriptLoaded) {
        alert("Failed to load Razorpay SDK. Please try again later.");
        return;
      }
      // Create an order on the backend
      let data = {
        plan_id: values.id,
        workspace_id: state.workspace_id,
      };
      await payments.renewPlan(data).then((res) => {
        if (res.data.status) {
          const options = {
            key: "rzp_test_j5pzqGxqRuC8FP", // Replace with your Razorpay Key ID
            amount: res.data.order.amount, // Amount in paise
            currency: res.data.order.currency,
            name: "Cloudzilla",
            description: "Razorpay Integration",
            order_id: res.data.order.id,
            handler: function (response) {
              let data= {
                  order_id: response.razorpay_order_id,
                  payment_id: response.razorpay_payment_id,
                  signature: response.razorpay_signature,
                }
             payments.verifyPlan(data)
                .then((response) => {
                  if (response.status === 200) {
                    setOpen(true);
                    paymentDetails()
                    setSeverity("success");
                    setPaymentDialog(false)
                    setMessage("Payment successful");
                  } else {
                    setOpen(true);
                    setSeverity("error");
                    setMessage("Payment verification failed");
                  }
                })
                .catch((error) => {
                  console.error("Error verifying payment:", error);
                });
            },
            prefill: {
              name: values.userName,
              email: values.email,
              contact: values.contact,
            },
            theme: {
              color: "#F37254",
            },
          };
          const razorpay = new window.Razorpay(options);
          razorpay.open();
        } else {
          setOpen(true);
          setSeverity("error");
          setMessage(res.data.message);
        }
      });

      // Initialize Razorpay
    } catch (error) {
      console.error("Error creating order:", error);
    }
  };

  let [paymentInfo,setPaymentInfo] = useState(false)


  let paymentDetails =(()=>{
    apiData.getUserPlanDetails(state.workspace_id).then((res)=>{
      if(res.status ===200){
        setPaymentInfo(res.data.plan)
      }
    })
  })
  useEffect(()=>{
    paymentDetails()
  },[])

    const renderInputField = (
        label,
        name,
        value,
        placeholder,
        handleChange,
        touched,
        error,
        type
      ) => {
        return (
          <FormControl variant="standard" sx={{ marginTop: "4px" }} fullWidth>
            <CustomTypography>{label}</CustomTypography>
            <CustomInput
            autoComplete="off"
              error={Boolean(touched && error)}
              name={name}
              value={value}
              type={type ? type : "text"}
              onChange={handleChange}
              placeholder={placeholder}
              size="small"
            />
            <FormHelperText error sx={{ fontFamily: "Inter" }}>
              {touched && error}
            </FormHelperText>
          </FormControl>
        );
      };
  
    

      const handleOnSubmit = (values) => {
        const data = {
          "email": state.email,
          "old_password":values.oldPassword,
          "new_password": values.password
        }
        try{
          loginApi.changePassword(data).then((res) => {
            if(res.data ==="Invalid OTP"){
              setOpen(true);
              setSeverity('error')
              setMessage('Invalid OTP') 
            }else if (res?.data?.message === "Password changed successfully.") {
              setOpen(true);
              setSeverity('success')
              setMessage('Password changed successfully')
              setTimeout(()=>{
                // navigate('/signin')
              },[5000])
              setEdit(false)
            } else {
              setOpen(true)
              setSeverity('error')
              setMessage(res.data.message)
            }
          });
        }catch{
            setSeverity('error')
              setMessage("Failed to update")
          setOpen(true)
        }
      };
    return (
        <Page>
            <Box>
                <Toolbar />
                <FrameParent>
                    <Card sx={{ display: 'flex', flexDirection: 'column', width: '600px', margin: '100px',  borderRadius: '50px', padding: '2rem', alignItems: 'center', boxShadow: '1px 1px 7px #b2b0ce' }}>
                        <Box sx={{position:'absolute'}}>
                        <Avatar sx={{ width: '150px', height: '150px', fontSize: '48px', position:'relative',marginTop: '-100px',  textTransform: 'uppercase' }}>{state.email?.charAt(0)}</Avatar>
                        </Box>
                        {edit ?
                            <Box sx={{ borderRadius: '16px', marginTop: '80px', width: '400px', padding: '1rem' }}>
                      
                              
                                    <Box
                                        sx={{
                                            width: "100%",
                                            // boxShadow: "1px 1px 10px 0px lightGrey",
                                            height: "80vh",
                                        }}
                                    >
                                        <Formik
                                            initialValues={{
                                                oldPassword: "",
                                                confirmPassword: "",
                                                password: "",
                                            }}
                                            validationSchema={Yup.object().shape({
                                                oldPassword: Yup.string().required("Please old password"),
                                                confirmPassword: Yup.string()
                                                    .required("Please enter confirm password")
                                                    .oneOf([Yup.ref("password"), null], "Passwords must match"),
                                                password: Yup.string().required(" Please enter new password")
                                                    .matches(passwordRegex, "Password must contain atleast minimum eight characters,one letter, one number and one special character")
                                                ,
                                            })}
                                            onSubmit={(values) => {
                                                handleOnSubmit(values);
                                            }}
                                        >
                                            {({
                                                errors,
                                                setFieldValue,
                                                handleChange,
                                                handleSubmit,
                                                touched,
                                                submitCount,
                                                values,
                                            }) => (
                                                <form noValidate onSubmit={handleSubmit}>
                                                    {/* <pre>{JSON.stringify({ values, errors }, null, 4)}</pre> */}

                                                    <Container maxWidth={"xs"}>
                                                        <Box sx={{ minHeight: 200 }}>
                                                            {renderInputField(
                                                                "Old password*",
                                                                "oldPassword",
                                                                values.oldPassword,
                                                                "Enter old password",
                                                                handleChange,
                                                                touched.oldPassword,
                                                                errors.oldPassword,
                                                                "password"
                                                            )}
                                                            {renderInputField(
                                                                "New Password*",
                                                                "password",
                                                                values.password,
                                                                "Enter new password",
                                                                handleChange,
                                                                touched.password,
                                                                errors.password,
                                                                "password"
                                                            )}

                                                            {renderInputField(
                                                                "Confirm New Password*",
                                                                "confirmPassword",
                                                                values.confirmPassword,
                                                                "Enter confirm password",
                                                                handleChange,
                                                                touched.confirmPassword,
                                                                errors.confirmPassword,
                                                                "password"
                                                            )}

                                                        </Box>
                                                        <LoginButton
                                                            type={"submit"}
                                                            fullWidth
                                                            disable
                                                            sx={{ textAlign: "center",width:'100%' }}
                                                        >
                                                            Reset Password
                                                        </LoginButton>
                                                        <Box sx={{width:'100%', textAlign:'center', marginTop:'2rem'}}>
                                                            <IconButton  onClick={()=>setEdit(false)}>
                                                                 <CancelOutlined/>
                                                            </IconButton>
                                                        </Box>
                                                      
                                                    </Container>
                                                </form>
                                            )}
                                        </Formik>
                                    </Box>
                            </Box>
                            : <Box sx={{ borderRadius: '16px', marginTop: '80px',height:'max-content', width: '400px', padding: '1rem' }}>
                                <Grid container spacing={2} >
                                 <Grid item xs={6} sx={{fontSize:'10px'}} mt={2}>
                                    {/* <Stack direction={'row'} > */}
                                        <Typography  sx={{fontSize:'14px'}}>Subscription:</Typography>  
                                        <Typography sx={{color:'green',fontWeight:'bold',fontSize:'14px'}}>{paymentInfo.name}</Typography>
                                    {/* </Stack> */}
                                </Grid>
                                <Grid item xs={6}  mt={2} textAlign={'end'}>
                                    <Stack direction={'column'} sx={{fontSize:'10px'}}>
                                        <Typography sx={{fontSize:'14px'}}>Expires on:</Typography>  
                                        <Typography sx={{fontWeight:'bold',fontSize:'14px'}}> {moment(paymentInfo.plan_end_date).format('YYYY-MM-DD')}</Typography>
                                    </Stack>
                                </Grid>
                                <Grid item xs={12}>
                                <LoginButton
                                                            fullWidth
                                                            disable
                                                            sx={{ textAlign: "center",width:'100%',marginTop:0 }}
                                                            onClick={()=>setPaymentDialog(true)}
                                                        >
                                                            <img src="https://images.slivcdn.com/UI_icons/New_Final_Icons_30052020/premium_icon_3x_new.png?h=24&w=24&q=high" width={20} style={{marginRight:'4px'}} alt=""/>
                                                            Renew Plan
                                                        </LoginButton>
                                    </Grid>

                                    <Grid item xs={1} mt={2}>
                                        <Email />
                                    </Grid>
                                    <Grid item xs={11}>
                                        <CustomTypography sx={{ fontFamily: 'Open Sans', fontWeight: '600' }}>Email</CustomTypography>
                                        <Typography sx={{ fontFamily: 'Open Sans', fontWeight: '500', marginLeft:'4px'  }}>{state.email}</Typography>
                                    </Grid>
                                    <Grid item xs={1} mt={2}>
                                        <CloudCircle />
                                    </Grid>
                                    <Grid item xs={11} >
                                        <CustomTypography sx={{ fontFamily: 'Open Sans', fontWeight: '600' }}>Bucket name</CustomTypography>
                                        <Typography sx={{ fontFamily: 'Open Sans', fontWeight: '500', marginLeft:'4px' }}>{state.workspace}</Typography>
                                    </Grid>
                                    <Grid item xs={1} mt={2}>
                                        <SettingsIcon />
                                    </Grid>
                                    <Grid item xs={11}>
                                        <CustomTypography sx={{ fontFamily: 'Open Sans', fontWeight: '600' }}>Change Password</CustomTypography>
                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                            <Typography sx={{ fontFamily: 'Open Sans', fontWeight: '500', marginLeft:'4px'  }}>*********</Typography>
                                            <IconButton onClick={sendOtpForEmail} sx={{ padding: '4px', ml: 1 }}>
                                                <Edit sx={{ color: 'grey', fontSize: '24px' }} />
                                            </IconButton>
                                        </div>
                                    </Grid>
                                    <Grid item xs={4}>
                                    </Grid>
                                    <Grid item xs={8} sx={{ display: 'flex', alignItems: 'center' }}>
                                    </Grid>
                                </Grid>
                            </Box>}
                    </Card>
                </FrameParent>
            </Box>
            <Alert severity={severity} handleClose={() => setOpen(false)} message={message} open={open} />

            <CreateDialog
            open={paymentDialog}
            size="sm"
            setLock={""}
            handleClose={() => setPaymentDialog(false)}
            title="Renew Plan"
            children={
              <PaymentCourosel
                renewPlan={true}
                handleSubmit={handlePayment}
              />
            }
          />
        </Page>
    )
}

export default Profile