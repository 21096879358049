const ApiEndPoint = "https://api.cloudzilla.cloud/company";
const SECRET_KEY = "XThNdJqwNJbKFOQYPeGjY0iDCcAWeKPD!!@cloudzilla!!M0hL4CmcTov1fw1jKq9y5oOhF25fZThw";

// const ApiEndPoint = "https://8ceb-2406-7400-51-8423-dbe6-2a87-b378-88cf.ngrok-free.app/company";
// const SECRET_KEY = "somerandomtext!!@cloudzilla!!SecretKEY";

module.exports = {
  ApiEndPoint,
  SECRET_KEY,
};
